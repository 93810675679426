<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>All Customers</h1>
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Postcode</th>
                <th class="text-left">Business Type</th>
                <th class="text-left">Main contact</th>
                <th class="text-left">Tel</th>
                <th class="text-left">Email</th>
                <th class="text-center">Sage Ref?</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="customer in customers"
                :key="customer.id"
              >
                <td>
                  <router-link :to="`/customers/view/${customer.id}`">
                    {{ customer.name }}
                  </router-link>
                </td>
                <td>{{ customer.postcode }}</td>
                <td>{{ customer.type }}</td>
                <td>{{ customer.mainContact }}</td>
                <td>{{ customer.tel }}</td>
                <td>{{ customer.email }}</td>
                <td class="text-center">
                  <span v-if="customer.onSage">
                    <v-icon color="green">mdi-check-bold</v-icon>
                  </span>
                  <span v-else>
                    <v-icon color="red">mdi-close-thick</v-icon>
                  </span>
                </td>
                <td>
                  <router-link custom v-slot="{ navigate }" :to="`/customers/view/${customer.id}`">
                    <v-btn
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                      View
                    </v-btn>
                  </router-link>
                  &nbsp;
                  <router-link custom v-slot="{ navigate }" :to="`/customers/edit/${customer.id}`">
                    <v-btn
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                      Edit
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-row class="no-print">
      <v-col>
        <v-btn @click="exportCustomers">Export</v-btn>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'CustomersIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      customers: [],
      page: 1,
      length: 0,
      totalVisible: 9,
    };
  },
  watch: {
    page() {
      this.getCustomers();
    },
  },
  methods: {
    getCustomers() {
      axios.get(`/customers/getAll/${this.page}.json?token=${this.token}`)
        .then((response) => {
          this.customers = response.data.customers;
          this.length = response.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportCustomers() {
      axios.get(`/spreadsheets/customers.json?token=${this.token}`, {responseType:'blob'})
          .then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'customers.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          });
    },
  },
  mounted() {
    this.getCustomers();
  },
};
</script>
